import { createContext } from 'react';

interface CustomProps {
  storeId: string;
  currentLatitude: string;
  currentLongitude: string;
}

export const CustomContext = createContext<
  CustomProps | undefined
>(undefined);

export const buildUrl = (categoryName: string, storeId: number | string) => {
  switch (categoryName) {
      case 'OFERTAS':
          return `/client/store/${storeId}/planogram/soldout?perPage=15`;
      case 'COMPRE DE NOVO':
          return `/client/store/${storeId}/planogram/buyagain?perPage=15`;
      default:
          return `/client/store/${storeId}/planogram?category=${categoryName}&perPage=15`;
  }
};

import styled from "styled-components/native";

export const Button = styled.TouchableOpacity<Props>`
	border-radius: 12px;
	justify-content: center;
	background-color: "white";
	align-items: center;
	padding-horizontal: 20px;
`;

type Props = {
	index: number;
};

type ContainerProps = {
	dataLength: number;
};

export const Image = styled.Image<Props>`
	width: 100%;
	height: 168px;
	border-radius: 12px;
	border: 1px solid #F6F6F6;
`;

export const Dot = styled.View`
	flex-direction: row;
	justify-content: center;
	
`;
export const DotItem = styled.View<{ active: boolean }>`
	height: 10px;
	width: 10px;
	background-color: ${props => props.active ? "#FF7700" : "#D9D9D9"}  ;
	margin-top: 13px;
	margin-right: 8px;
	border-radius: 50px;
`;
export const Container = styled.View<ContainerProps>`
  margin-bottom: 22px;
	${(props) => {
		if (props.dataLength == 0) {
			return "margin-bottom: 10px";
		}
	}}
`;

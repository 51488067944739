import { useQuery } from '@tanstack/react-query';
import api from "../../services/api"
import { Store as StoreInterface } from "../../models/store";

export const KEY_CATEGORY = 'KEY_CATEGORY';

interface CategoryResponse {
  store: StoreInterface,
  plans: any
}

export function useGetCategory(storeId: string) {
  const getCategoryStore = async () => {
    const storeRes = await api.get<CategoryResponse>(
      `/client/store/${storeId}/all-info?pwa=true`,
    );

    const { store, plans } = storeRes.data;

    const productsCategories: any[] = [];

    if (plans) {
      plans.forEach((item:{products: any}) => {
        if (item.products.length) productsCategories.push(item);
      });
    }

    const category = productsCategories.map((item: {index: number, category: string}, index: number) => {
			return { id: index, name: item.category };
		});

    return { store, category }
  };

  return useQuery({
    queryKey: [KEY_CATEGORY, storeId],
    queryFn: getCategoryStore,
    enabled: !!storeId,
  })
}

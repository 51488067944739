import { createContext } from "react";
import { CartItem } from "../models/cart";
import { Planogram } from "../models/planogram";
import { Store } from "../models/store";

interface CartContextProps {
	updateCartItem: (
		planogram: Planogram,
		quantity: number,
		redirect: boolean,
		clearCart: boolean,
		confirmRemoveItem: boolean,
		store?: Store,
	) => Promise<boolean>;
	clearCart: (isResetStore: boolean) => void;
	getCart: () => CartItem[];
	cartIds: number[];
	getStore: () => Store | undefined;
	itemsTotal: number;
	isAlreadyCreated: boolean;
	setIsAlreadyCreated: (bool: boolean) => void;
	setStore: (value: Store) => void;
}

export const CartContext = createContext({} as CartContextProps);
import React, { useEffect } from "react";
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import {
	Montserrat_400Regular,
	Montserrat_500Medium,
	Montserrat_600SemiBold,
	Montserrat_700Bold,
	useFonts,
} from "@expo-google-fonts/montserrat";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query"
import { NavigationContainer } from "@react-navigation/native";
import AppLoading from "expo-app-loading";
import { onMessage } from "firebase/messaging";
import { Provider } from "react-native-paper";
import { CartProvider } from "./src/providers/cart-provider";
import { CustomProvider } from "./src/providers/custom-provider";
import { ModalProvider } from "./src/providers/modal-provider";
import { NotificationBarProvider } from "./src/providers/notification-bar-provider";
import Routes from "./src/routes/routes";
import { messaging } from "./src/services/firebase";
import pkg from "./package.json";
import { connectSocket } from "./src/services/socket";

Sentry.init({
	dsn: process.env.SENTRY_DNS,
	integrations: [
		new Integrations.BrowserTracing(),
		new Sentry.Replay()],
	tracesSampleRate: 1.0,
	release: pkg.version,
	autoSessionTracking: true,
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});


export default function App() {
	let [fontsLoaded] = useFonts({
		Montserrat_400Regular,
		Montserrat_500Medium,
		Montserrat_600SemiBold,
		Montserrat_700Bold,
	});

	React.useEffect(() => {
		onMessage(messaging, (payload) => {
			const { title, body, ...options } = payload.notification;
			navigator.serviceWorker.register("firebase-messaging-sw.js");
			function showNotification() {
				Notification.requestPermission(function (result) {
					if (result === "granted") {
						navigator.serviceWorker.ready.then(function (
							registration
						) {
							registration.showNotification(title, {
								body: body,
								icon: "/icon96x96.png",
								badge: "/icon96x96.png",
							});
						});
					}
				});
			}
			showNotification();
		});
	}, []);

	useEffect(() => {
		connectSocket();
	}, [])

	const queryClient = new QueryClient()

	if (!fontsLoaded) {
		return <AppLoading />;
	}

	return (
		<Provider>
			<CustomProvider>
				<NavigationContainer>
					<NotificationBarProvider>
					<QueryClientProvider client={queryClient}>
						<ModalProvider>
							<CartProvider>
								<Routes />
							</CartProvider>
							</ModalProvider>
							</QueryClientProvider>
					</NotificationBarProvider>
				</NavigationContainer>
			</CustomProvider>
		</Provider>
	);
}

import React, { Fragment, useEffect, useMemo, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useIsFocused } from "@react-navigation/core";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { v4 as uuidv4 } from "uuid";
import { Header } from "../../components/Header";
import { ModalInHouse } from "../../components/ModalInHouse/modalInHouse";
import { ProductsClass } from "../../components/ProductsClass";
import Carrossel from "../../components/Carrossel/Carousel";
import SearchButton from "../../components/SearchButton";
import { useCart } from "../../hooks/use-cart";
import { useStore } from "../../hooks/use-store";
import { Planogram } from "../../models/planogram";
import { Store as StoreInterface } from "../../models/store";
import api from "../../services/api";
import {
	CategoriesContainer,
	CategoriesContainerContent,
	CategoriesContent,
	Container,
	Content,
	EmptyMessage,
	EmptyStore,
	TextCategories,
	ContainerAreaSpaceButton,
	LockIcon,
	ContainerAreaSpaceButtonLock,
	UpContainer,
	IconBarcode
} from "./styles";
import { Button } from "../../components/Button";
import ModalStatusLock from "../../components/ModalStatusLock";
import ScanQRCode from "../../components/ScanQRCode";
import { useGetCategory } from "../../store/category";
import { useGetProducts } from "../../store/products";
import BarcodeButton from "../../components/BarcodeButton";
import ScanBarcode from "../../components/ScanBarcode";
import ModalNotBarcode from "../../components/ModalNotBarcode";
import axios from "axios";
import { lockStores } from "../../utils/idsLocksStores";

type Navigation = NavigationProp<any>;

interface Lock {
	id: string;
	is_for_legal_age: boolean;
	qrcode: string;
	status: number;
}

const Home: React.FC = () => {
	const [visible, setVisible] = useState(false);
	const [visibleHeader, setVisibleHeader] = useState(false);
	const [stores, setStores] = useState<Array<StoreInterface>>([]);
	const [selectedProduct, setSelectedProduct] = useState<Array<number>>([]);
	const [modalProductNotFound, setModalProductNotFound] = useState(false);
	const [camOpened, setCamOpened] = useState(false);
	const [barcodeOpened, setBarcodeOpened] = useState(false);
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [modalInfo, setModalInfo] = useState<{
		error: 'ERROR' | 'SUCCESS' | 'ALERT';
		title: string
		description: string
		onTimeClose: boolean
		action: () => void
	}>({
		title: '',
		description: '',
		error: 'SUCCESS',
		onTimeClose: false,
		action: () => {}
	})

	const isFocused = useIsFocused();
	const navigation = useNavigation<Navigation>();
	const cart = useCart();
	const cartItems = useMemo(() => cart.getCart(), [cart]);
	const [result, setResult] = useState('');
	const paramStore = useStore();
	const type = "pwa";

	const {
		data: categoryData,
	} = useGetCategory(paramStore.storeId)

	const {
		data: productData,
		isLoading: isLoadingProductData
	} = useGetProducts(paramStore.storeId)

	const handleSelectedIds = (plan: Planogram) => {
		let data = [...selectedProduct];
		if (data.includes(plan.id)) {
			data = data.filter((item) => item != plan.id);
		} else {
			data.push(plan.id);
		}
		setSelectedProduct(data);
	};

	const getStores = () => {
		api.get(
			`/store/location?latitude=${paramStore.currentLatitude}&longitude=${paramStore.currentLongitude}&distance=9999999999999&type=${type}`
		).then((response) => {
			const data = response.data.filter((store: { id: number }) => !lockStores.includes(store?.id)).sort((a: any, b: any) =>
				a.name.localeCompare(b.name)
		);
			setStores(data);
		});
	};

	useEffect(() => {
		getStores();
	}, []);

	const hideModalAndShowHeader = () => {
		setVisible(false);
		setVisibleHeader(true);
	};

	const hideModal = () => {
		setVisible(false);
	};

	const openListApartment = () => {
		AsyncStorage.setItem("@openHeader", "true");
		setVisibleHeader(true);
	};

	const handleChangeQuantity = async (plan: Planogram, qnty: number) => {
		const cartItem = cartItems.find((item) => item.id === plan?.id);
		const actualQnty = !cartItem ? 1 : qnty - cartItem.quantity;
		cart.updateCartItem(plan, actualQnty, false, false, false, productData?.store);
	};

	const handleNavigateToScreen = (screenName: string, params: Object | undefined = undefined,) => {
		navigation.navigate(screenName, {
			params,
		});
	};

	const haveSomeLockToUnlock = React.useMemo(() => {
		return productData?.lock?.some((item: Lock) => item.status);
	}, [productData?.store, productData?.lock]);

	const handleOpenLock = async (lockId?: string) => {
		try {
			await api.get<Planogram>(`/store/${productData?.store.id}/lock${lockId ? `/${lockId}` : ''}`);
			setCamOpened(false)
			setModalInfo({
				...modalInfo,
				title: 'Comando de abertura de trava enviado com sucesso!',
				description: 'Verifique se a trava foi aberta',
				error: 'SUCCESS',
				onTimeClose: true
			})
		} catch (err) {
			setCamOpened(false)
			setModalInfo({
				...modalInfo,
				title: 'Comando de abertura de trava falhou!',
				description: 'Tente novamente ou contate o suporte',
				error: 'ERROR',
				onTimeClose: false
			})
		} finally {
			setIsOpenModal(true)
		}
	}

	const failedToOpenQrCodeScan = () => {
		setCamOpened(false)
		setModalInfo({
			...modalInfo,
			title: 'Comando de abertura de trava falhou!',
			description: 'Tente novamente ou contate o suporte',
			error: 'ERROR',
			onTimeClose: false
		})
		setIsOpenModal(true)
	}

	const handleQRCodeScan = (result: { data: string }) => {
		const lock = productData?.lock?.filter(
			(item: Lock) => item.qrcode == result.data && item.status,
		);
		if (lock && lock?.length <= 0) {
			setCamOpened(false)
			setModalInfo({
				...modalInfo,
				title: 'Verifique se o QR Code está correto ou contate o suporte',
				description: 'QR Code inválido',
				error: 'ALERT',
				onTimeClose: false
			})
			setIsOpenModal(true)
			return
		}

		handleOpenLock(lock && lock.length > 0 ? lock[0]?.id : '');
		setCamOpened(false)
	}

	function shouldScanQRCode(locks: Lock[], store: any) {
		return (
			locks.some((item: any) => Boolean(item.status)) &&
			Boolean(store.is_qr_code_required)
		);
	}


	const onPressUnlock = () => {
		if (shouldScanQRCode(productData ? productData?.lock : [], productData?.store)) return setCamOpened(true);
		handleOpenLock();
	}

	useEffect(() => {
		AsyncStorage.setItem("@openHeader", "false");
	}, []);

	useEffect(() => {
		if (!isFocused) return;
		if (paramStore.storeId === '') setVisible(true);
	}, [isFocused]);

	const verifyAndCreateUUID = React.useCallback(async () => {
		const item = await AsyncStorage.getItem("id_pwa");
		if (item) return;
		AsyncStorage.setItem("id_pwa", uuidv4());
	}, []);

	const verifySelectedProds = React.useCallback(async () => {
		setSelectedProduct((selected) => {
			return selected.filter((item) => cart.cartIds.includes(item));
		});
	}, [cart.cartIds]);

	useEffect(() => {
		verifyAndCreateUUID();
	}, []);

	useEffect(() => {
		verifySelectedProds();
	}, [verifySelectedProds]);

	useEffect(() => {
		if (camOpened || barcodeOpened) {
			navigation.setOptions({
				tabBarStyle: { display: "none" },
			});
			return
		}
		navigation.setOptions({
			tabBarStyle: { height: '80px', display: 'flex' },
		});
	}, [camOpened, barcodeOpened]);

	const handleReadBarCode = async (code: string) => {
		try {
			const response = await api.get<Planogram>(
				`/store/${paramStore.storeId}/planogram/barcode/${code}`,
			);
			if (!response.data) {
				setModalProductNotFound(true)
				return;
			}

			handleNavigateToScreen('Product', { product: response.data })
		} catch (error) {
			if (axios.isAxiosError(error)) setModalProductNotFound(true);
		}
	};

	return (
		<>
			{camOpened && <ScanQRCode setCamOpened={setCamOpened} getResult={handleQRCodeScan} onError={failedToOpenQrCodeScan} />}
			{barcodeOpened && <ScanBarcode barcodeOpened={barcodeOpened} setBarcodeOpened={setBarcodeOpened} onResult={handleReadBarCode} onError={()=> setModalProductNotFound(true)} />}

			{!camOpened && !barcodeOpened &&
				<>
					<Header visibleHeader={visibleHeader} hideModalAndShowHeader={hideModalAndShowHeader} />
					<Container>
						<Content>
							{!productData?.productsCategories?.length && !isLoadingProductData ? (
								<EmptyStore>
									<EmptyMessage>
										Não há produtos cadastrados nessa loja
									</EmptyMessage>
								</EmptyStore>
							) : (
								<CategoriesContainer
									showsHorizontalScrollIndicator={false}
								>
									<CategoriesContainerContent
										horizontal
										showsHorizontalScrollIndicator={false}
									>
										{categoryData?.category?.length && categoryData?.category.map((item: { id: number, name: string }) => {
											return (
												<CategoriesContent
													selected={false}
													key={item.id}
													onPress={() => handleNavigateToScreen('Categorias', item.name)}
												>
													<TextCategories selected={false}>
														{item.name}
													</TextCategories>
												</CategoriesContent>
											);
										})}
									</CategoriesContainerContent>
									{productData?.advert && productData?.advert?.length > 0 && (
										<Carrossel
											item={productData.advert || []}
											handleNavigateTo={(ads: any) => handleNavigateToScreen('Ad', { ads })}
										/>
									)}
									{productData?.soldout && productData?.soldout?.length > 0 && (
										<ProductsClass
											products={productData.soldout || []}
											selectedProduct={selectedProduct}
											title="Ofertas"
											seeMoreAction={() => handleNavigateToScreen('Categorias', "OFERTAS")}
											onQuantityChange={handleChangeQuantity}
											onQuantityOpen={handleSelectedIds}
											onQuantityPress={() =>
												setSelectedProduct([])
											}
											onProductPress={(product: any) => handleNavigateToScreen('Product', { product })}
										/>
									)}

									{productData?.productsCategories && productData?.productsCategories?.map((item) => {
										return (
											<Fragment key={item.category}>
												<ProductsClass
													onPressAds={(ads: any) => handleNavigateToScreen('Ad', { ads })}
													products={item.products}
													selectedProduct={selectedProduct}
													title={item.category}
													seeMoreAction={() => handleNavigateToScreen('Categorias', item.category)}
													onQuantityChange={
														handleChangeQuantity
													}
													onQuantityOpen={handleSelectedIds}
													onQuantityPress={() =>
														setSelectedProduct([])
													}
													onProductPress={(product: any) => handleNavigateToScreen('Product', { product })}
												/>
											</Fragment>
										);
									})}
								</CategoriesContainer>
							)}
						</Content>
						{visible && (
							<ModalInHouse
								params={paramStore}
								hideModal={hideModal}
								openListApartment={openListApartment}
								hideModalAndShowHeader={hideModalAndShowHeader}
								store={productData?.store}
							/>
						)}

					<UpContainer>
						<BarcodeButton onPress={() => setBarcodeOpened(!barcodeOpened)} />
					</UpContainer>

					<ContainerAreaSpaceButton>
						<SearchButton />
					</ContainerAreaSpaceButton>

						{haveSomeLockToUnlock &&
							<ContainerAreaSpaceButtonLock>
								<Button type="primary" onPress={onPressUnlock}>
									<LockIcon source={require('../../assets/lock.svg')} />
									Destravar porta
								</Button>
							</ContainerAreaSpaceButtonLock>
						}

					</Container>
				</>
			}

			{isOpenModal &&
				<ModalStatusLock
					isOpen={isOpenModal}
					setIsOpen={setIsOpenModal}
					type={modalInfo.error}
					title={modalInfo.title}
					description={modalInfo.description}
					action={modalInfo.action}
					onTimeClose={modalInfo.onTimeClose} />
			}

			<ModalNotBarcode
				isOpen={modalProductNotFound}
				setIsOpen={setModalProductNotFound}
				title="Código de barras não encontrado!"
				description="Você pode pesquisar seu produto pelo nome"
				action={() => navigation.navigate("PesquisarProdutos")}
			/>
		</>
	);
};

export default Home;

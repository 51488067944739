import React, { useState, useMemo, useEffect, useCallback } from "react";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { CartItem } from "../../components/CartItem";
import { ModalAgeRestricted } from "../../components/ModalAgeRestricted";

import {
	Container,
	Content,
	TitleCart,
	ContainerContentEmptyCart,
	ContentEmptyCart,
	EmptyCartContainer,
	EmptyCartIcon,
	NoItemsText,
	ConfirmButton,
	TextConfirmButton,
	TotalContainer,
	TotalContainerContent,
	TotalContainerContentText,
	TotalContainerContentTextValue,
	TotalContainerContentValue,
	ItemsScroll,
	CartContentContainer,
	BtnContainer,
	ContentButtonProducts,
	LineContainer,
	LineText,
	ChangeButton,
	TextChange,
	HeaderCart,
	HeaderPrevLink,
	StoreAreaInfo,
	StoreTitle,
	StoreAreaTitle,
	StoreLogo,
	StoreAddress,
	CardItemsArea,
	LineArea,
	PurchaseDetails,
	BtnArea,
	HeaderButtonPrev,
	PaymentLineContainer,
	PaymentDetailsContainer,
	PaymentDetailsTitle,
	PaymentDetailsDescription,
	PaymentChangeText,
	NoteIcon,
	PaymentTitle,
	IconContaine
} from "./styles";
import { useCart } from "../../hooks/use-cart";
import { NumberToCurrency } from "../../utils/numberToCurrency";
import HeaderNavigation from "../../components/HeaderNavigation";
import ModalAddCpf from "../../components/ModalAddCpf";
import { getFormattedRemoteConfig } from "../../utils/remoteConfig";

const emptyCartIcon = require("../../../assets/emptyCart.svg");
const noteIcon = require("../../../assets/note.svg");

type Navigation = NavigationProp<any>;

const Cart: React.FC = () => {
	const myCart = useCart();
	const cartItems = useMemo(() => myCart.getCart(), [myCart]);
	const storeInfo = useMemo(() => myCart.getStore(), [myCart])

	const [userCPF, setUserCPF] = useState('');
	const [showInputCPF, setShowInputCPF] = useState(false);

	const navigation = useNavigation<Navigation>();
	const store = useMemo(() => myCart.getStore(), [myCart]);
	const [isOpen, setIsOpen] = useState<boolean>(false)

	const remoteConfig = getFormattedRemoteConfig()?.ENABLED_PWA_ADD_CPF === "true"

	const isActiveFiscal = remoteConfig && !!storeInfo?.shopManager?.emit_invoice

	const handleNavigateToStore = () => {
		navigation.navigate("Loja");
	};

	const handleNavigateToPix = () => {
		const hasMinorProhibitedProducts = cartItems.reduce(
			(all, curr) => all || curr.is_for_legal_age,
			false,
		)

		if (hasMinorProhibitedProducts && store?.is_qr_code_required == 0) {
			setIsOpen(true)
			return
		}

		navigation.navigate("Pagar", { cpf: userCPF });
	};

	const onFinishCart = () => {
		navigation.navigate("Pagar", { cpf: userCPF });
	}

	const finalTotal = React.useMemo(() => {
		return cartItems.reduce((total, item) => {
			return total + item.final_price_in_cents * item.quantity;
		}, 0);
	}, [cartItems]);

	const getSubTotal = useCallback(
		() =>
			cartItems.reduce(
				(all, cartItem) =>
					cartItem.last_price_in_cents > cartItem.final_price_in_cents
						? (cartItem.last_price_in_cents / 100) * cartItem.quantity + all
						: (cartItem.final_price_in_cents / 100) * cartItem.quantity + all,
				0,
			),
		[cartItems],
	);

	const getDiscountInCents = () => {
		const listPriceTotal = cartItems.reduce((total, item) => {
			return total + item.last_price_in_cents * item.quantity;
		}, 0);

		return finalTotal < listPriceTotal ? listPriceTotal - finalTotal : 0;
	};

	useEffect(() => {
		if (cartItems.length > 0) {
			navigation.setOptions({ tabBarStyle: { display: 'none', } });
			return;
		}
		setUserCPF("")
		navigation.setOptions({ tabBarStyle: { display: 'flex', height: 80 } });
	}, [cartItems]);

	return (
		<>
			<Container>
				<Content>
					<HeaderNavigation enableButtonLeft enableButtonRight={false} title="Carrinho" />

					{cartItems.length === 0 ? (
						<EmptyCartContainer>
							<ContainerContentEmptyCart>
								<ContentEmptyCart>
									<EmptyCartIcon source={emptyCartIcon} />
									<NoItemsText>
										Seu carrinho de compras ainda está vázio
									</NoItemsText>
								</ContentEmptyCart>
								<ContentButtonProducts>
									<ConfirmButton
										onPress={handleNavigateToStore}
									>
										<TextConfirmButton>
											VER PRODUTOS
										</TextConfirmButton>
									</ConfirmButton>
								</ContentButtonProducts>
							</ContainerContentEmptyCart>
						</EmptyCartContainer>
					) : (
						<>
							<StoreAreaInfo>
								<StoreLogo source={{ uri: storeInfo?.logo }} />
								<StoreAreaTitle>
									<StoreTitle
										numberOfLines={1}
										ellipsizeMode="tail"
									>
										{storeInfo?.name}
									</StoreTitle>
									<StoreAddress>
										{storeInfo?.address.street}
									</StoreAddress>
								</StoreAreaTitle>
							</StoreAreaInfo>
							<CartContentContainer>
								<TotalContainer>
									<TotalContainerContent>
										<TotalContainerContentTextValue>
											Total ({myCart.itemsTotal} un)
										</TotalContainerContentTextValue>
									</TotalContainerContent>
									<TotalContainerContentValue>
										<TotalContainerContentTextValue>
											{NumberToCurrency(
												cartItems.reduce(
													(all, curr) =>
														all +
														(curr.final_price_in_cents /
															100) *
														curr.quantity,
													0
												)
											)}
										</TotalContainerContentTextValue>
									</TotalContainerContentValue>
								</TotalContainer>
								<ItemsScroll>
									<CardItemsArea>
										{cartItems.map((item, index) => (
											<CartItem
												key={index}
												item={item}
												isLast={cartItems.length - 1 === index}
											/>
										))}
									</CardItemsArea>
									<LineArea>
										<LineContainer first>
											<LineText color="tertiary">
												Subtotal
											</LineText>
											<LineText
												color="tertiary"
												fontWeight="bold"
												testID="subtotal_purchase"
											>
												{NumberToCurrency(getSubTotal())}
											</LineText>
										</LineContainer>
										<LineContainer>
											<LineText color="#B0B0B0">
												Desconto
											</LineText>
											<LineText
												color="#B0B0B0"
												fontWeight="bold"
												testID="dicount"
											>
												{NumberToCurrency(
													getDiscountInCents() / 100
												)}
											</LineText>
										</LineContainer>

										<LineContainer isLast>
											<LineText color="tertiary">Total</LineText>
											<LineText
												color="#2081FF"
												isTotal
												fontWeight="bold"
												testID="total_purchase"
											>
												{NumberToCurrency(finalTotal / 100)}
											</LineText>
										</LineContainer>
									</LineArea>

									<PurchaseDetails padding={isActiveFiscal}>
										<TotalContainerContent>
											<TotalContainerContentTextValue isLeft={isActiveFiscal}>
												Total ({myCart.itemsTotal} un)
											</TotalContainerContentTextValue>
										</TotalContainerContent>
										<TotalContainerContentValue>
											<TotalContainerContentTextValue isRigth={isActiveFiscal}>
												{NumberToCurrency(
													cartItems.reduce(
														(all, curr) =>
															all +
															(curr.final_price_in_cents /
																100) *
															curr.quantity,
														0
													)
												)}
											</TotalContainerContentTextValue>
										</TotalContainerContentValue>
									</PurchaseDetails>
									{isActiveFiscal && (
										<>
											<PaymentTitle>Nota Fiscal</PaymentTitle>
											<PaymentLineContainer>
												<IconContaine>
													<NoteIcon source={noteIcon} resizeMode="contain" />
												</IconContaine>
												<PaymentDetailsContainer>
													<PaymentDetailsTitle testID="cpf_label">
														{userCPF
															? 'Alterar CPF da nota fiscal'
															: 'Deseja adicionar CPF na nota fiscal ?'}
													</PaymentDetailsTitle>
													{userCPF && (
														<PaymentDetailsDescription testID="cpf_label_number">
															{userCPF}
														</PaymentDetailsDescription>
													)}
												</PaymentDetailsContainer>
												<PaymentChangeText
													color={null}
													onPress={() => setShowInputCPF(true)}
													testID="btn_add_cpf"
												>
													{userCPF
														? 'Trocar'
														: 'Adicionar'}
												</PaymentChangeText>
											</PaymentLineContainer>
										</>
									)}
								</ItemsScroll>
							</CartContentContainer>

							<BtnContainer>
								<BtnArea>
									<ChangeButton
										onPress={handleNavigateToStore}
									>
										<TextChange>
											Continuar <br /> comprando
										</TextChange>
									</ChangeButton>
								</BtnArea>
								<BtnArea>
									<ConfirmButton
										onPress={handleNavigateToPix}
									>
										<TextConfirmButton>
											Finalizar
										</TextConfirmButton>
									</ConfirmButton>
								</BtnArea>
							</BtnContainer>
						</>
					)}
				</Content>
			</Container>

			<ModalAgeRestricted
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				title="Confirmação de idade"
				description="Você confirma ter 18 anos de idade ou mais ?"
				action={onFinishCart}
				testID="modal_age_verification"
			/>
			{showInputCPF && (
				<ModalAddCpf
					userCPF={userCPF}
					setUserCPF={setUserCPF}
					setOpen={setShowInputCPF}
				/>
			)}
		</>
	);
};

export default Cart;

import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { FlatList, useWindowDimensions } from "react-native";
import { Header } from "../../components/Header";
import { Product } from "../../components/Product";
import { useCart } from "../../hooks/use-cart";
import { NavigationProp, RouteProp, useNavigation } from "@react-navigation/native";

import {
	Container,
	Content,
	CategoriesContainer,
	CategoriesContainerContent,
	CategoriesContent,
	TitleCategories,
	TextCategories,
	Products,
	ContainerBtnSearch,
	ButtonContainer,
	TextLoadingCategory,
} from "./styles";
import { Planogram } from "../../models/planogram";
import { BaseClass } from "../../components/BaseClassCategory";
import SearchButton from "../../components/SearchButton";
import { useStore } from "../../hooks/use-store";
import { useGetProducts } from "../../store/category-product";
import { useGetCategory } from "../../store/category";

interface Category {
	id: number;
	name: string;
}

interface RouteParams {
  key: string;
	name: string;
	params: PropsCategory;
	path: any;
}

interface PropsCategory {
	params: string
}

type Navigation = NavigationProp<any>;

type CategoryRouteProp = RouteProp<Record<string, RouteParams>, 'Category'>;

const Category: React.FC<{ route?: CategoryRouteProp }> = ({ route }) => {
	const { goBack } = useNavigation();
	const navigation = useNavigation<Navigation>();
	const [category] = useState<string | any>(route?.params?.params)
	const [categoryName, setCategoryName] = useState(category);
	const [selectedProduct, setSelectedProduct] = useState<Planogram>();
	const [numColumns, setNumColumns] = useState(2);

	const cart = useCart();
	const cartItems = useMemo(() => cart.getCart(), [cart]);
	const paramStore = useStore();
	const dimensions = useWindowDimensions();

	const flatListRef = useRef(null);

	const {
		data: productCategory,
		fetchNextPage: fetchNextPageProduct,
		hasNextPage: hasNextPageProduct,
		isInitialLoading: isInitialLoadingProduct,
		isFetching
	} = useGetProducts(categoryName, paramStore.storeId);

	const {
		data: categoryData,
	} = useGetCategory(paramStore.storeId)

	const flattenedProducts = useMemo(() => {
		return productCategory?.pages?.flatMap((page) => page?.data) ?? [];
	}, [productCategory, categoryName]);


	const amount = cartItems.reduce((sumAmount: any, product: any) => {
		sumAmount[product.id] = product.quantity;
		return sumAmount;
	}, {});

	const refreshCategories = async (name: string) => {
		if (name == categoryName) {
			return goBack();
		}

		setCategoryName(name)
	};

	const handleChangeQuantity = (product: Planogram, qnty: number) => {
		const cartItem = cartItems.find((item) => item.id === product.id);
		const actualQnty = !cartItem ? 1 : qnty - cartItem.quantity;
		cart.updateCartItem(product, actualQnty, false, false, false, categoryData?.store);
	};

	const handleNavigateToScreen = (screenName: string, params: Object | undefined = undefined,) => {
		navigation.navigate(screenName, {
			params,
		});
	};

	useEffect(() => {
		const productCardWidth = 160;
		const marginOfEachProductCard = 20;
		const cardQuantityPerLine = Math.floor(dimensions.width / (productCardWidth + marginOfEachProductCard))
		setNumColumns(cardQuantityPerLine);
	}, [dimensions])

	const handleIntersection = (entries: IntersectionObserverEntry[]) => {
		const entry = entries[0];
		if (entry.isIntersecting && hasNextPageProduct) {
			fetchNextPageProduct();
		}
	};

	useEffect(() => {
		const observer = new IntersectionObserver(handleIntersection, { threshold: 0.1 });

		if (flatListRef.current) {
			observer.observe(flatListRef.current);
		}

		return () => {
			if (flatListRef.current) {
				observer.unobserve(flatListRef.current);
			}
		};
	}, [flatListRef.current]);

	const renderItemProduct = ({ item }: any) => (
		<Product
			key={item.id}
			title={item?.product.name}
			imgUrl={item?.product?.image_url}
			price_list={parseFloat(item?.price_list_in_cents) / 100}
			quantity={amount[item?.id] || 0}
			maxQuantity={item.stock_amount}
			onQuantityChange={(qtd: number) => handleChangeQuantity(item, qtd)}
			quantityOpen={cart.cartIds.includes(item?.id)}
			onQuantityOpen={() => setSelectedProduct(item)}
			onQuantityPress={() => setSelectedProduct(undefined)}
			price_final={parseFloat(item.price_final_in_cents) / 100}
			style={{
				marginBottom: 20,
				marginLeft: 20,
			}}
			testID={item.id.toString()}
			onPress={() => handleNavigateToScreen('Product', { product: item })}
		/>
	);

	const keyExtractor = useCallback((item: any, i: number) => `${i}-${item?.id}`, []);

	return (
		<>
			<Header />
			<Container>
				<Content>
					<CategoriesContainer showsHorizontalScrollIndicator={false}>
						<TitleCategories>Categorias</TitleCategories>
						<CategoriesContainerContent
							horizontal
							showsHorizontalScrollIndicator={false}
						>
								{categoryData?.category?.length && categoryData?.category.map((item: {id: number, name: string}) => {
								return (
									<CategoriesContent
										key={item.id.toString()}
										selected={item.name == categoryName}
										onPress={() =>
											refreshCategories(item.name)
										}
									>
										<TextCategories
											selected={item.name == categoryName}
										>
											{item.name}
										</TextCategories>
									</CategoriesContent>
								);
							})}
						</CategoriesContainerContent>

						<Products>
							<FlatList
								ListHeaderComponent={() => (
									!isInitialLoadingProduct ? <BaseClass title={categoryName} /> : null
								)}
								data={flattenedProducts || []}
								key={numColumns}
								extraData={cart?.cartIds}
								keyExtractor={keyExtractor}
								renderItem={renderItemProduct}
								numColumns={numColumns}
								showsVerticalScrollIndicator={false}
								ListFooterComponent={() => (
									<ButtonContainer ref={flatListRef}>
										{isInitialLoadingProduct && (
											<TextLoadingCategory>Carregando Produtos...</TextLoadingCategory>
										)}
									</ButtonContainer>
								)}
							/>
						</Products>
						{isFetching && !isInitialLoadingProduct && (
							<ButtonContainer ref={flatListRef}>
								<TextLoadingCategory>
								Carregando Produtos...
								</TextLoadingCategory>
							</ButtonContainer>
						)}
						
					</CategoriesContainer>
				</Content>
				<ContainerBtnSearch>
					<SearchButton />
				</ContainerBtnSearch>
			</Container>
		</>
	);
};

export default Category;

import React, { ReactFragment } from "react";
import {
	Container,
	Header,
	SeeMoreButton,
	SeeMoreText,
	ItemsHorizontalScroll,
	ScrollInner,
	Title,
} from "./styles";

interface BaseClassProps {
	title: string;
	isLast?: boolean;
	seeMoreAction?: () => void;
	children: ReactFragment;
}

export const BaseClass: React.FC<BaseClassProps> = ({
	title,
	seeMoreAction,
	isLast = false,
	children,
}) => {
	return (
		<Container isLast={isLast}>
			<Header>
				<Title testID="base_class_title">{title}</Title>
				{seeMoreAction && (
					<SeeMoreButton
						onPress={seeMoreAction}
						testID="btn_see_more"
					>
						<SeeMoreText>Ver mais</SeeMoreText>
					</SeeMoreButton>
				)}
			</Header>
			<ItemsHorizontalScroll
				horizontal
				showsHorizontalScrollIndicator={false}
			>
				<ScrollInner>{children}</ScrollInner>
			</ItemsHorizontalScroll>
		</Container>
	);
};

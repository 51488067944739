import { Feather } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useEffect, useState } from "react";
import { FlatList, Linking, View } from "react-native";
import { Searchbar } from "react-native-paper";
import polygonIcon from "../../../assets/Polygon.png";
import { theme } from "../../global/theme";
import { useStore } from "../../hooks/use-store";
import { Store as StoreInterface } from "../../models/store";
import api from "../../services/api";
import { useNavigation } from "@react-navigation/native";
import { BASE_URL } from "../../utils/contants";
import { formatDistance } from "../../utils/formatDistance";
import ScrollViewIndicator from "react-native-scroll-indicator";
import {
	Address,
	ArrowIcon,
	Box,
	Container,
	ContainerAddressButton,
	ContainerContentList,
	ContainerIconAlert,
	ContainerOpacity,
	Content,
	InfoView,
	LinkToStore,
	SeeMoreSection,
	StoreDetails,
	StoreLogo,
	StoreTitle,
	TextApartmentComplex,
	TextApartmentComplexDistance,
} from "./styles";
import { SimpleLineIcons } from "@expo/vector-icons";
import { useCart } from "../../hooks/use-cart";
import { lockStores } from "../../utils/idsLocksStores";

interface HeaderProps {
	title?: string;
	hideBackBtn?: boolean;
	backgroundColor?: string;
	isTitleCentered?: boolean;
	visibleHeader?: boolean;
  hideModalAndShowHeader?: () => void;
}

export const Header: React.FC<HeaderProps> = ({ visibleHeader, hideModalAndShowHeader }) => {
	const logoIcon = require("../../../assets/logoHeader.png");
	const paramStore = useStore();
	const cart = useCart();
	const navigation = useNavigation();
	const [store, setStore] = useState<StoreInterface>({} as StoreInterface);
	const [searchBarOpen, setSearchBarOpen] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [stores, setStores] = useState([]);
	const type = "pwa";
  
  	const getStoreData = async () => {
		try {
			if (paramStore.storeId) {
				const storeTemp = await api.get<StoreInterface>(
					`/store/${paramStore.storeId}`
				);

				if (storeTemp.data && lockStores.includes(storeTemp.data?.id)) {
					throw new Error("Store not found");
				}
				setStore(storeTemp.data);
				cart.setStore(storeTemp.data)
			}
		} catch (error: any) {
			hideModalAndShowHeader()
		}
	};

	useEffect(() => {
		getStoreData();
		getStores();
		const headerOpen: any = AsyncStorage.getItem("@openHeader");
		if (headerOpen === "true" || visibleHeader === true) {
			setSearchBarOpen(true);
		}
	}, [visibleHeader]);

	const getStores = () => {
		api.get(
			`/store/location?latitude=${paramStore.currentLatitude}&longitude=${paramStore.currentLongitude}&distance=9999999999999&type=${type}`
		).then((response) => {
			const data = response.data.filter((store: { id: number }) => !lockStores.includes(store?.id)).sort((a: any, b: any) =>
				a.name.localeCompare(b.name)
		);
			setStores(data);
		});
	};

	const toglleSearchBar = () => {
		setSearchBarOpen(!searchBarOpen);
		visibleHeader = !visibleHeader;
		if (searchBarOpen === false) {
			AsyncStorage.setItem("@openHeader", "true");
		} else {
			AsyncStorage.setItem("@openHeader", "false");
		}
	};

	const renderItem = ({ item }: { item: StoreInterface }) => (
		<ContainerContentList key={item.id}>
			<LinkToStore href={`${BASE_URL}?store=${item.id}`}>
				<TextApartmentComplex>{item.name}</TextApartmentComplex>
			</LinkToStore>
		</ContainerContentList>
	);

	const handleNavigateToSupport = () => {
		navigation.navigate("Support"); //aqui
	};

	return (
		<>
			<Container backgroundColor={theme.colors.tertiary}>
				<Content>
					<Box size={0.8} alignItems="center">
						<StoreLogo
							key={store.id}
							source={ { uri: store.logo } }
							testID="logo"
						/>
						<InfoView>
							<StoreTitle
								numberOfLines={1}
								ellipsizeMode="tail"
								testID="name"
							>
								{store.name}
							</StoreTitle>
							
								<Address testID="address">
									{store?.address?.street}
								</Address>
						</InfoView>
					</Box>

					<Box size={0.2}>
						<ContainerIconAlert onPress={handleNavigateToSupport}>
							<SimpleLineIcons
								name="question"
								size={24}
								color="white"
							/>
						</ContainerIconAlert>
					</Box>
				</Content>
			</Container>
			{searchBarOpen && (
				<SeeMoreSection>
					<StoreDetails testID="details">
						<Searchbar
							placeholder="Digite o nome da unidade"
							onChangeText={(text) => setSearchQuery(text)}
							value={searchQuery}
							style={{
								borderRadius: 10,
								fontFamily: theme.fonts.regular,
								fontSize: 15,
								marginBottom: 20,
							}}
						/>
						<ScrollViewIndicator
							shouldIndicatorHide={false}
							flexibleIndicator={false}
							scrollIndicatorStyle={{
								backgroundColor: theme.colors.primary,
							}}
							scrollIndicatorContainerStyle={{
								backgroundColor: "rgba(0, 0, 0, 0.1)",
							}}
						>
							{stores
								.filter(
									(item: any) =>
										item.is_open === 1 &&
										item.name.includes(searchQuery.toUpperCase())
								)
								.map((item: any) => (
									<ContainerContentList key={item.id}>
										<LinkToStore
											href={`${BASE_URL}?store=${item.id}`}
										>
											<TextApartmentComplex>
												{item.name}
											</TextApartmentComplex>
										</LinkToStore>
									</ContainerContentList>
								))}
						</ScrollViewIndicator>
					</StoreDetails>
				</SeeMoreSection>
			)}
			<ContainerOpacity
				style={{
					display: searchBarOpen === true ? "flex" : "none",
				}}
			/>
		</>
	);
};

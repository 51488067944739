import React from "react";
import { Button, Image } from "./styles";

const CarouselItem = ({ item, index, handleNavigateTo }: any) => {
	return (
		<Button onPress={() => handleNavigateTo(item)}>
			<Image
				source={{ uri: item?.image }}
				index={index}
				resizeMode="stretch"
			/>
		</Button>
	);
};

export default CarouselItem;

import React from "react";
import { FlatList } from "react-native";
import { Planogram } from "../../models/planogram";
import { BaseClass } from "../BaseClass";
import { Product } from "../Product";
import { useCart } from "../../hooks/use-cart";

interface ProductsClassProps {
	title: string;
	isLast?: boolean;
	seeMoreAction?: () => void;
	onPressAds?: (value: any) => void;
	products: Planogram[];
	selectedProduct: Array<number>;
	onQuantityOpen: (planogram: Planogram) => void;
	onQuantityChange: (planogram: Planogram, qnty: number) => void;
	onQuantityPress: () => void;
	onProductPress: (product: Planogram) => void;
}

export const ProductsClass: React.FC<ProductsClassProps> = ({
	title,
	seeMoreAction,
	products,
	selectedProduct,
	onQuantityOpen,
	onQuantityChange,
	onQuantityPress,
	isLast = false,
	onProductPress,
}) => {
	const cart = useCart();
	const cartItems = React.useMemo(() => cart.getCart(), [cart]);

	const rebindFunction = (plan: Planogram, e: number) => {
		onQuantityChange(plan, e);
	};

	return (
		<BaseClass
			title={title}
			isLast={isLast}
			seeMoreAction={seeMoreAction}
		>
			{products?.map((product, index) => {
				return (
					<Product
						key={index}
						onPress={() =>
							onProductPress && onProductPress(product)
						}
						imgUrl={product?.product?.image_url}
						onQuantityChange={(e) => rebindFunction(product, e)}
						onQuantityOpen={() => onQuantityOpen(product)}
						onQuantityPress={() => {}}
						price_list={
							parseFloat(product?.price_list_in_cents) / 100
						}
						quantity={
							cartItems.find((item) => item.id === product.id)
								?.quantity || 0
						}
						title={product?.product.name}
						price_final={
							parseFloat(product?.price_final_in_cents) / 100
						}
						quantityOpen={
							cartItems.length > 0 &&
							selectedProduct.includes(product?.id)
						}
						style={{
							marginRight: 14,
						}}
						maxQuantity={product.stock_amount}
						testID={product.id.toString()}
					/>
				);
			})}
		</BaseClass>
	);
};

import { useQuery } from "@tanstack/react-query";
import api from "../../services/api";
import { Planogram } from "../../models/planogram";
import { Store as StoreInterface } from "../../models/store";

export const PRODUCTS_STORES = 'PRODUCTS_STORES';

interface Lock {
    id: string;
    is_for_legal_age: boolean;
    qrcode: string;
    status: number;
}

interface propsResponse {
    store: StoreInterface
    advert: Planogram[]
    soldout: Planogram[]
    buyagain: Planogram[]
    plans: any
    lock: Lock[]
}

interface ProductsByCategory {
    category: string;
    products: Planogram[];
}

export function useGetProducts(storeId: string) {
    const queryFn = async () => {
        const { data: { store, advert, soldout, buyagain, plans, lock } } = await api.get<propsResponse>(`/client/store/${storeId}/all-info?pwa=true`);

        const productsCategories: ProductsByCategory[] = [];

        plans.forEach((item: any) => {
            if (item.products.length) productsCategories.push(item);
        });

        return {
            advert: advert.length ? advert : [],
            soldout: soldout.length ? soldout : [],
            buyagain: buyagain.length ? buyagain : [],
            store,
            productsCategories,
            lock,
        }
    };
    return useQuery({
        queryKey: [PRODUCTS_STORES, storeId],
        queryFn,
        enabled: !!storeId,
    })
}

import { useInfiniteQuery } from "@tanstack/react-query";
import api from "../../services/api";
import { PlanogramResponse } from "../../models/planogram";
import { buildUrl } from "./urlBuilder";

export const CATEGORY_PRODUCTS = 'CATEGORY_PRODUCTS';

export function useGetProducts(categoryName: string, storeId: string) {
    const getProducts = async (page = 1) => {
        const url = buildUrl(categoryName, storeId);

        const { data } = await api.get<PlanogramResponse>(url, {
            params: { page },
        });

        return {
            data: data?.data?.filter((item)=> item != null) || [],
            nextPage: page + 1,
        }
    };

    return useInfiniteQuery({
        queryKey: [CATEGORY_PRODUCTS, categoryName, storeId],
        queryFn: ({ pageParam }) => getProducts(pageParam),
        getNextPageParam: (lastPage) => {
            if (lastPage.data.length < 15) return undefined;
            return lastPage.nextPage;
        },
        enabled: !!storeId && !!categoryName
    });
}

import React, { useState } from "react";
import CarouselItem from "./CarouselItem ";
import { Planogram } from "../../models/planogram";
import { Dot, Container, DotItem } from "./styles";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

interface ProductsClassProps {
	item: Planogram[];
	handleNavigateTo: (item: any) => void;
}
const CarouselAll: React.FC<ProductsClassProps> = ({
	item: data = [],
	handleNavigateTo,
}) => {
	const [index, setIndex] = useState<number>(0);

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 5,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 2,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		}
	};

  const afterChange = (currentIndex: number, state: any) => {
		const nextIndex = state.currentSlide;
    setIndex(nextIndex);
  };


	return (
		<Container dataLength={data.length}>
			<Carousel
				afterChange={afterChange}
				swipeable={true}
				draggable={false}
				responsive={responsive}
				ssr={true}
				infinite={false}
				autoPlay={true}
				autoPlaySpeed={5000}
				keyBoardControl={true}
				customTransition="all .5"
				transitionDuration={500}
				containerClass="carousel-container"
				removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
				showDots={false}
			>
				{data && data.map((ad) => {
					return (
						<CarouselItem
							key={ad.id}
							item={ad}
							handleNavigateTo={handleNavigateTo}
						/>
					);
				})}
			</Carousel>
			{data.length > 1 && (
				<Dot>
					{data.map((_, i) => (
						<DotItem key={i} active={index === i} />
					))}
				</Dot>
			)}
		</Container>
	);
};

export default CarouselAll;
